<template>
  <div>
    <v-dialog v-model="addCertDialog" width="1100" scrollable persistent>
      <v-card>
        <v-card-title style="font-size: 20px; height: 70px">
          <p>
            <v-icon class="ma-1" style="font-size: 28px; color: #424242"
              >mdi-plus-box-outline</v-icon
            >
            {{ $t(dialog_title) }}
          </p>

          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="CancelCert()"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
              margin-bottom: 16px;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 700px">
          <v-form ref="form2" v-model="valid_form" lazy-validation>
            <!-- first page  -->
            <div v-if="AddCertPage == 1">
              <v-row class="mt-3">
                <v-col cols="12" lg="12">
                  <p style="color: #424242; font-size: 16px">
                    {{ $t("createdateM") }}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ this.createdate }} {{ $t("By") }} {{ this.createdBy }}
                  </p>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col cols="12" xs="12" sm="12" md="6">
                  <label for="title" class="font-style">
                    {{ $t("title") }}
                    <span style="color: red">*</span>
                  </label>
                  <v-text-field
                    :rules="titleRules"
                    outlined
                    dense
                    hide-details="auto"
                    v-model="certificate.title"
                    class="mt-2"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="mt-2">
                <v-col cols="12" xs="12" sm="12" md="6">
                  <label for="title" class="font-style">
                    {{ $t("description") }}
                  </label>
                  <v-text-field
                    outlined
                    dense
                    hide-details="auto"
                    v-model="certificate.description"
                    class="mt-2"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col cols="12" xs="12" sm="12" md="6">
                  <label
                    for="title"
                    class="font-style"
                    style="font-weight: bold"
                  >
                    {{ $t("choosetemplate") }}
                  </label>
                </v-col>
              </v-row>

              <v-row class="mr-5 ml-5">
                <v-col
                  cols="12"
                  xs="12"
                  sm="4"
                  md="4"
                  lg="4"
                  v-for="(item, i) in templateItems"
                  :key="i"
                >
                  <div class="drop">
                    <v-card
                      @click="ToOpenFile(item)"
                      @dragover.prevent
                      @dragenter.prevent
                      @drop.prevent="dropImg($event)"
                      class="scaled-container"
                    >
                      <v-img
                        v-if="item.templateImage"
                        :src="item.templateImage"
                        @mouseover="
                          item.templateImage
                            ? (item.showEditImgTool = true)
                            : (item.showEditImgTool = false)
                        "
                        @mouseleave="
                          item.templateImage
                            ? (item.showEditImgTool = false)
                            : (item.showEditImgTool = false)
                        "
                        width="300"
                        height="167"
                        class="mx-auto center"
                        contain
                      >
                        <!--  -->
                        <!--  
                      style="left: 15%; top: 15%; position: absolute" -->
                        <v-card-title v-if="item.showEditImgTool">
                          <v-list
                            class="d-flex flex-column align-start"
                            style="
                              padding: 0;
                              border-radius: 8px !important;
                              box-shadow: 0 0 7px 5px rgba(0, 0, 0, 0.2) !important;
                            "
                          >
                            <v-list-item class="list-item-padding">
                              <v-btn
                                color="#424242"
                                style="font-size: 14px; min-width: 100px"
                                text
                                class="text-capitalize"
                                @click.stop="
                                  (previewimg = true),
                                    (previewImage = item.templateImage)
                                "
                              >
                                <v-icon left>mdi-eye-outline</v-icon>
                                {{ $t("preview") }}
                              </v-btn>
                            </v-list-item>
                            <v-list-item
                              class="list-item-padding"
                              v-show="item.id == 0"
                            >
                              <v-btn
                                color="error"
                                style="font-size: 14px; width: 100px"
                                text
                                class="text-capitalize"
                                @click.stop="item.templateImage = null"
                              >
                                <v-icon left>mdi-delete-outline</v-icon>
                                {{ $t("delete") }}
                              </v-btn>
                            </v-list-item>
                          </v-list>
                        </v-card-title>
                      </v-img>

                      <label
                        v-else
                        for="title"
                        class="font-style center"
                        style="
                          font-weight: bold;
                          text-align: center;
                          display: block;
                        "
                      >
                        {{ $t("selectfile") }}
                      </label>
                    </v-card>
                    <v-radio-group
                      v-model="certificate.checkTemplate"
                      style="margin: 0%"
                    >
                      <v-radio
                        :value="item.id"
                        color="#a6cc39"
                        hide-details
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
              </v-row>
              <v-row class="ml-5" v-show="fileError">
                <label
                  for="title"
                  class="font-style"
                  style="font-weight: bold; color: #fa5252"
                >
                  {{ $t("selectcertificatetemplate") }}
                </label>
              </v-row>
            </div>
            <!-- second page -->

            <ReviseCertificate
              v-if="AddCertPage == 2"
              :selected_certificate="selectedTemp"
              :draggable_fields="draggable_fields"
              ref="childComponent"
            />

            <!-- third page -->
            <div v-if="AddCertPage == 3" class="px-2">
              <v-row class="mt-3 pl-8 pt-3">
                <label
                  for="title"
                  class="font-style"
                  style="font-weight: bold; font-size: 18px"
                >
                  {{ $t("setupsendingcert") }}
                </label>
              </v-row>
              <v-row class="pl-8">
                <v-radio-group v-model="certificate.emailFormat">
                  <v-radio
                    :label="$t('sendemailautomatically')"
                    :value="1"
                  ></v-radio>
                  <v-card
                    flat
                    width="650"
                    class="ml-10 mt-3 pa-4"
                    style="
                      background: #f8f8f8 0% 0% no-repeat padding-box;
                      border-radius: 0px !important;
                    "
                  >
                    <v-row class="ml-5">
                      <label
                        for="title"
                        class="font-style"
                        style="font-weight: bold; margin-top: 10px"
                      >
                        {{ $t("conditionsforautomatic") }}
                      </label>
                    </v-row>
                    <v-row class="ml-5 mt-5 mb-5">
                      <label
                        for="title"
                        class="font-style ml-5"
                        style="text-indent: 10%"
                      >
                        {{ $t("usersmustcomplete") }}
                      </label>
                    </v-row>
                  </v-card>
                  <v-radio
                    :label="$t('sendemailmanually')"
                    :value="2"
                    class="mt-5"
                  ></v-radio>
                </v-radio-group>
              </v-row>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="my-2">
          <v-spacer></v-spacer>
          <v-btn
            text
            outlined
            width="100"
            class="text-capitalize mr-3"
            @click="CancelCert()"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            class="text-capitalize"
            width="100"
            color="secondary"
            @click="ClickNext()"
            :disabled="AddCertPage == 2 ? disabledNextBtn : false"
            v-if="AddCertPage == 1 || AddCertPage == 2"
          >
            {{ $t("next") }}
          </v-btn>
          <v-btn
            class="text-capitalize"
            width="100"
            color="secondary"
            @click="
              addCertificate
                ? SaveCertificateAndSendMail()
                : updateCertificateAndSendMail()
            "
            v-else
          >
            {{ addCertificate ? $t("save") : $t("update") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <input
      type="file"
      class="d-none"
      ref="filetemplate"
      accept="image/*"
      @change="uploadTemplate($event, 1)"
    />

    <!-- preview image -->
    <v-dialog
      v-model="previewimg"
      persistent
      width="800px"
      height="800px"
      overflow="hidden"
      content-class="elevation-0 preview_class"
    >
      <v-card overflow="hidden" color="transparent">
        <v-card-title style="padding-right: 0 !important">
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="previewimg = false"
            color="#FFFFFF"
            style="border-radius: 8px !important; border: 1px solid #ffffff30"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-img
          width="800px"
          height="500px"
          contain
          style="border-radius: 8px; margin: auto"
          :src="previewImage"
        ></v-img>
      </v-card>
    </v-dialog>

    <!-- Loading -->
    <v-dialog v-model="showLoading" persistent width="300">
      <v-card color="#4caf50" dark class="py-1">
        <v-card-text class="white--text">
          {{ $t('loadingpleasewait') }}
          <v-progress-linear
            indeterminate
            color="#ffffff"
            class="mb-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import ReviseCertificate from "./ReviseCertificate.vue";
import certificateJS from "./certificate.js";

export default {
  components: {
    ReviseCertificate,
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    q_Item: {
      type: Object,
      default: null,
    },
    dialog_title: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    disabledNextBtn: false,
    draggable_fields: [
      {
        text: "image",
        type: "stamp_Img",
        width: "20",
        icon: require("@/assets/images/Stamp.svg"),
        disabled: false,
        placeholder: "",
      },
      {
        text: "Full name",
        type: "full-name",
        width: "24",
        icon: require("@/assets/images/Pen.svg"),
        disabled: false,
        placeholder: "Full Name",
      },
      {
        text: "textbox",
        type: "text",
        width: "24",
        icon: require("@/assets/images/text_A.svg"),
        disabled: false,
        placeholder: "enter-text",
      },
      {
        text: "date-stamp",
        type: "date",
        width: "20",
        icon: require("@/assets/images/calendar.svg"),
        disabled: false,
        placeholder: "",
      },
      {
        text: "course-name",
        type: "course-name",
        width: "24",
        icon: require("@/assets/images/pen_2.svg"),
        disabled: false,
        placeholder: "",
      },
    ],
    addCertificate: null,
    edit_elementItem: [],
    showLoading: false,
    selectedTemp: null,
    valid_form: true,
    //createdBy: null,
    createdate: moment(new Date().toISOString().substr(0, 10))
      .local()
      .format("DD MMM YYYY"),
    certificate: {
      fileName: null, // remove or not
      checkTemplate: null, // stroe template id
      title: null,
      description: null,
      templateImage: null,
      templatePDF: null,
      quizID: null,
      courseID: 0,
      emailFormat: 0,
      createBy: "",
      width: 0,
      height: 0,
      certTemplateMasterID: 0,
      plainTemplate: null,
      status: null,
      certificatePositions: [],
    },
    templateItems: [],
    // showEditImgTool: false,
    fileError: false,
    previewimg: false,
    previewImage: "",
    AddCertPage: 1,
    selectFileTemplate: [
      {
        id: 0,
        templateImage: "",
        activeFlag: true,
        showEditImgTool: false,
      },
    ],
  }),

  computed: {
    addCertDialog: {
      get() {
        return this.show_dialog;
      },
      set() {
        this.$emit("close_dialog");
      },
    },
    titleRules() {
      return [(v) => !!v || "Title is required"];
    },
    createdBy: {
      get() {
        return this.q_Item.create_by;
      },
    },
  },
  watch: {
    addCertDialog(val) {
      if (val) {
        this.GetDefaultTemplate();
      }
    },
  },

  mounted() {
    this.$emit("close_dialog");
  },

  methods: {
    insertDataToEdit() {
      let that = this;
      let copy_cert = that.q_Item.certificate_Item;

      that.certificate = {
        certificateID: copy_cert.certificateID,
        title: copy_cert.title,
        description: copy_cert.description,
        templateImage: copy_cert.templateImage,
        templatePDF: copy_cert.templatePDF,
        quizID: copy_cert.quizID,
        courseID: copy_cert.courseID,
        status: copy_cert.status,
        emailFormat: copy_cert.emailFormat,
        width: copy_cert.width,
        height: copy_cert.height,
        plainTemplate: copy_cert.plainTemplate,
        certificatePositions: [],
        checkTemplate: copy_cert.certTemplateMasterID,
        createBy: copy_cert.createBy,
      };

      if (copy_cert.certTemplateMasterID != 0) {
        that.selectedTemp =
          that.templateItems[copy_cert.certTemplateMasterID].templateImage;
      } else {
        that.selectedTemp = copy_cert.plainTemplate;
      }
      that.edit_elementItem = copy_cert.certificatePositions;
      //...store elements in vuex....
      that.$store.commit("saveCertElementsToEdit", that.edit_elementItem);

      if (that.q_Item.course_name === null) {
        that.draggable_fields[4].disabled = true;
      } else {
        that.draggable_fields[4].placeholder = that.q_Item.course_name;
      }
      that.AddCertPage = 2;
    },

    async SaveCertificateAndSendMail() {
      let that = this;
      that.showLoading = true;

      let imagedata = "";
      if (that.certificate.checkTemplate == 0) {
        const base64Res = await that.$axios.post(
          `${that.web_url}File/UploadFileStringBase`,
          {
            base64: that.templateItems[0].templateImage,
            fileName: that.certificate.fileName,
          }
        );
        imagedata = base64Res.data;
      }
      let plain_temp = certificateJS.removeToken(that.selectedTemp);

      let request = {
        certificateID: 0,
        title: that.certificate.title,
        description: that.certificate.description,
        templateImage: that.certificate.templateImage,
        templatePDF: that.certificate.templatePDF,
        quizID: localStorage.getItem("QuizId"),
        courseID: that.q_Item.courseId,
        status: true,
        certTemplateMasterID: that.certificate.checkTemplate,
        emailFormat: that.certificate.emailFormat,
        createBy: that.q_Item.memberid,
        width: that.certificate.width,
        height: that.certificate.height,
        plainTemplate:
          that.certificate.checkTemplate != 0 ? plain_temp : imagedata, // certificate bg template...
        certificatePositions: that.certificate.certificatePositions,
      };
      console.log("save req=> ", request);
      await axios
        .post(`${that.web_url}Certificate/AddCertificate`, request)
        .then(function (res) {
          if (res.data.status == 0) {
            that.CancelCert();
            that.showLoading = false;
          }
        });
    },

    async updateCertificateAndSendMail() {
      let that = this;
      that.showLoading = true;
      let plain_temp = certificateJS.removeToken(that.certificate.plainTemplate);      

      let request = {
        certificateID: that.certificate.certificateID,
        title: that.certificate.title,
        description: that.certificate.description,
        templateImage: that.certificate.templateImage,
        templatePDF: that.certificate.templatePDF,
        quizID: that.certificate.quizID,
        courseID: that.certificate.courseID,
        status: that.certificate.status,
        certTemplateMasterID: that.certificate.checkTemplate,
        emailFormat: that.certificate.emailFormat,
        createBy: that.certificate.createdBy,
        width: that.certificate.width,
        height: that.certificate.height,
        plainTemplate: plain_temp, // that.certificate.plainTemplate, // certificate bg template...
        certificatePositions: that.certificate.certificatePositions,
      };
      console.log("update req=> ", request);
      await axios
        .post(`${that.web_url}Certificate/UpdateCertificate`, request)
        .then(function (res) {
          if (res.data.status == 0) {
            that.CancelCert();
            that.showLoading = false;
          }
        });
    },

    CancelCert() {
      let that = this;
      //that.addCertDialog = false;
      that.AddCertPage = 1;
      that.certificate.title = null;
      that.certificate.description = null;
      that.certificate.fileName = null;
      that.certificate.checkTemplate = null;
      that.certificate.emailFormat = 0;
      that.fileError = false;
      that.$store.commit("removeCertElementsData");
      that.$emit("close_dialog");
    },

    async GetDefaultTemplate() {
      let that = this;
      that.showLoading = true;
      await axios
        .get(`${that.web_url}Certificate/GetTemplateImageMaster`)
        .then(function (response) {
          const res_temp = response.data.data.map((x) => ({
            ...x,
            showEditImgTool: false,
          }));
          if (response.data.status == 0) {
            that.templateItems = [...that.selectFileTemplate, ...res_temp];

            that.showLoading = false;

            if (that.q_Item.type == 1) {
              that.AddCertPage = 1;
              that.addCertificate = true;
            } else {
              that.insertDataToEdit();
              that.addCertificate = false;
            }
          }
        });
    },

    change_to_Percentage(item_val, container_val) {
      let result = (item_val / container_val) * 100;
      return result;
    },

    ClickNext() {
      let that = this;
      var index = that.certificate.checkTemplate;

      if (that.AddCertPage === 1) {
        if (this.$refs.form2.validate()) {
          if (that.certificate.checkTemplate == null) {
            that.fileError = true;
          } else if (
            (that.certificate.checkTemplate == 0 &&
              that.templateItems[0].templateImage == "") ||
            that.templateItems[0].templateImage == null
          ) {
            // check whether only the radio is select but not attach picture
            that.fileError = false;
            alert("Please attach a certificate template!");
          } else {
            if (that.q_Item.course_name === null) {
              that.draggable_fields[4].disabled = true;
            } else {
              that.draggable_fields[4].placeholder = that.q_Item.course_name;
            }
            that.AddCertPage = 2;
            that.selectedTemp = that.templateItems[index].templateImage;
          }
        } else {
          if (that.certificate.checkTemplate == null || that.certificate.title == null || that.certificate.title == "") {
            that.fileError = true;
          } else if (
            that.certificate.checkTemplate == 0 &&
            that.templateItems[0].templateImage == ""
          ) {
            alert("Please attach a certificate template!");
          } else {
            that.AddCertPage = 2;
            that.selectedTemp = that.templateItems[index].templateImage;
          }
        }
      } else if (that.AddCertPage === 2) {
        that.disabledNextBtn = true;        
        that.showLoading = true;
        that.update_certificatePositions();
      }
    },

    async update_certificatePositions() {
      const that = this;
      // that.showLoading = true;
      try {
        await Promise.all([
          that.$refs.childComponent.clearActiveBeforeCapture(),
          that.$refs.childComponent.captureAsImage(),
          //that.$refs.childComponent.captureAsPDF(),
          that.$refs.childComponent.uploadImgtoSave(),
          that.$refs.childComponent.getContainerDimensions(),
        ]);

        const items = that.$refs.childComponent.elements;
        const imgAndPdfFiles = that.$refs.childComponent.templateObj;
        const c_width = that.$refs.childComponent.imageWidth;
        const c_height = that.$refs.childComponent.imageHeight;

        if (imgAndPdfFiles.image) {
          that.showLoading = false;
          that.AddCertPage = 3;
          that.disabledNextBtn = false;
        }

        const updated_data = items.map((v) => ({
          //id: v.id,
          certificateID: v.certificateID != 0 ? v.certificateID : 0, //when add
          x: that.change_to_Percentage(v.x, c_width),
          y: that.change_to_Percentage(v.y, c_height),
          width: that.change_to_Percentage(v.width, c_width), // v.type == "stamp_Img" ?  : v.width,
          height: that.change_to_Percentage(v.height, c_height), // v.type == "stamp_Img" ?  : v.height,
          angle: 0,
          type: v.type,
          text: v.text,
          icon: v.icon,
          inputText: v.showText,
          imgUrl: v.img_blob,
          fontStyle: v.fontStyle,
          fontSize: v.fontSize,
          fontColor: v.fontColor,
          fontBoldItalicUnderline: v.fontBoldItalicUnderline,
        }));

        that.certificate.certificatePositions = updated_data;
        that.certificate.templateImage = imgAndPdfFiles.image;
        that.certificate.templatePDF = imgAndPdfFiles.pdf;
        that.certificate.width = c_width;
        that.certificate.height = c_height;

      } catch (error) {
        console.error("Error:", error);
      }
    },
    getFile(e) {
      this.templateItems[0].templateImage = e;
      this.templateItems[0].showEditImgTool = false;
    },
    ToOpenFile(item) {
      if (item.templateImage == "" || item.templateImage == null) {
        this.$refs.filetemplate.click();
      }
    },

    uploadTemplate(e, num) {
      let self = this;
      let file = e.target.files[0];
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {        
        alert("Please choose image!");
        return false;
      }
      this.certificate.fileName = file.name;
      let reader = new FileReader();
      reader.onload = function (f) {
        let data;
        if (typeof f.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([f.target.result]));
        } else {
          data = f.target.result;
        }
        if (num === 1) {
          // self.option.img = data;
          // self.dialogCrop = true;
          self.getFile(data);
        }
      };
      //  reader.readAsArrayBuffer(file);
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style scoped>
.font-style {
  color: #000000;
  font-size: 14px;
}
::v-deep .v-dialog > .v-card > .v-card__title {
  padding: 16px 16px 10px;
}
::v-deep .v-dialog > .v-card > .v-card__text {
  padding: 0 16px 10px !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
::v-deep .primary {
  background-color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}

.scaled-container {
  width: 300px;
  height: 200px;
  overflow: hidden;
  resize: both;
  border: 2px dashed #c0c0c0;
  box-shadow: unset !important;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

::v-deep .v-responsive__content {
  margin-left: -100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
::v-deep .v-list {
  border-radius: 8px !important;
}
::v-deep .list-item-padding {
  min-height: unset !important;
  padding: 0 !important;
}
</style>
