import jsPDF from "jspdf";

export default {


  removeToken(value) {
    if (!value) {
      // If value is null
      return null;
    }

    const searchString = "?";
    const index = value.indexOf(searchString);

    if (index !== -1) {
      // Remove everything after and including the '?' character
      return value.substring(0, index);
    } else {
      // No token found
      return value;
    }
  },

  async addTextToImageAndPdf(userName, baseImageItem, type) {

    // Create an off-screen canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    
    let imageUrl = baseImageItem?.templateImage;
    let positionArr = baseImageItem?.certificatePositions.filter(x => x.type === "full-name");

    // Load the image
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = imageUrl;

    return new Promise((resolve, reject) => {
      img.onload = () => {
        // Set canvas size to the image size
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0);

        const imageWidth = img.width;
        const imageHeight = img.height;

        // Loop through each position and draw the name
        positionArr.forEach(positionObj => {
          const nameX = Math.round((positionObj.x / 100) * imageWidth);
          const nameY = Math.round((positionObj.y / 100) * imageHeight);
          const textWidth = Math.round((positionObj.width / 100) * imageWidth);
          const textHeight = Math.round((positionObj.height / 100) * imageHeight);

          // text style.....
          const fontSize = positionObj.fontSize ? positionObj.fontSize * 2 : 14 * 2; // Use the appropriate font size
          const fontColor = positionObj.fontColor ? positionObj.fontColor : 'black'; // Use the specified color or default to black

          let bold = positionObj?.fontBoldItalicUnderline.find(x => x === 0) ?? null;

          let italic = positionObj?.fontBoldItalicUnderline.find(x => x === 1) ?? null;

          let underLine = positionObj?.fontBoldItalicUnderline.find(x => x === 2) ?? null;

          // Check for bold and italic
          const fontWeight = bold != null ? 'bold' : 'normal';
          const fontStyle = italic != null ? 'italic' : 'normal';
          const fontFamily = positionObj.fontStyle ? positionObj.fontStyle : "Poppins, sans-serif";

          // Set the complete font property
          ctx.font = `${fontStyle} ${fontWeight} ${fontSize}px ${fontFamily}`;
          ctx.fillStyle = fontColor; // Set text color

          // Handle underline (this needs to be drawn manually as canvas does not support underline directly)
          if (underLine) {
            const textMetrics = ctx.measureText(userName);
            const underlineY = nameY + (textHeight / 2) + 8; // Position the underline slightly below the text
            ctx.beginPath();
            ctx.moveTo(nameX + (textWidth / 2) - (textMetrics.width / 2), underlineY);
            ctx.lineTo(nameX + (textWidth / 2) + (textMetrics.width / 2), underlineY);
            ctx.lineWidth = 1.7; // Underline thickness
            ctx.strokeStyle = fontColor; // Underline color matches text color
            ctx.stroke();
          }

          // Ensure text is centered both horizontally and vertically
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(userName, nameX + textWidth / 2, nameY + textHeight / 2); // Center text within the box

        });

        if (type === 'image') {
          // Export the canvas as a PNG image
          const updatedImageUrl = canvas.toDataURL('image/jpeg', 0.7);
          resolve(updatedImageUrl);
        } else if (type === 'pdf') {
          // Export the canvas as a PDF
          const imgData = canvas.toDataURL('image/jpeg',0.7);

          // Use jsPDF to create a PDF
          const pdf = new jsPDF({
            orientation: imageWidth > imageHeight ? 'landscape' : 'portrait',
            unit: 'px',
            format: [imageWidth, imageHeight] // Match canvas size
          });

          // Add the canvas image to the PDF
          pdf.addImage(imgData, 'JPEG', 0, 0, imageWidth, imageHeight, undefined, "FAST");

          // Save or return the PDF
          const pdfOutput = pdf.output('blob');
          resolve(pdfOutput);
          //window.open(URL.createObjectURL(pdfOutput));
        }
      };

      img.onerror = (error) => reject(error);
    });
  }

  // async addTextToImage(userName, baseImageItem) {
  //   console.log("baseImageItem: ", baseImageItem);
  //   console.log("fullName: ", userName);

  //   // Create an off-screen canvas
  //   const canvas = document.createElement('canvas');
  //   const ctx = canvas.getContext('2d');

  //   let imageUrl = baseImageItem?.templateImage;
  //   // Get all positions for full-name text
  //   let positionArr = baseImageItem?.certificatePositions.filter(x => x.type === "full-name");
  //   console.log("item positions :", positionArr);

  //   // Load the image
  //   const img = new Image();
  //   img.crossOrigin = 'Anonymous';
  //   img.src = imageUrl;

  //   return new Promise((resolve, reject) => {
  //     img.onload = () => {
  //       // Set canvas size to the image size
  //       canvas.width = img.width;
  //       canvas.height = img.height;

  //       // Draw the image onto the canvas
  //       ctx.drawImage(img, 0, 0);

  //       // Use dynamic positioning based on the image dimensions
  //       const imageWidth = img.width;
  //       const imageHeight = img.height;
  //       console.log("template width & height = ", imageWidth, imageHeight);

  //       // Loop through each position and draw the name
  //       positionArr.forEach(positionObj => {
  //         // Calculate the text position based on percentages
  //         const nameX = Math.round((positionObj.x / 100) * imageWidth);
  //         const nameY = Math.round((positionObj.y / 100) * imageHeight);
  //         const textWidth = Math.round((positionObj.width / 100) * imageWidth);
  //         const textHeight = Math.round((positionObj.height / 100) * imageHeight);
  //         console.log("x,y,w,h => ", nameX, nameY, textWidth, textHeight);

  //         // Add the dynamic text for each position
  //         const fontSize = positionObj.fontSize ? positionObj.fontSize : 14; // Use the appropriate font size
  //         const fontColor = positionObj.fontColor ? positionObj.fontColor : 'black'; // Use the specified color or default to black

  //         let bold = positionObj?.fontBoldItalicUnderline.find(x => x === 0) ?? null;

  //         let italic = positionObj?.fontBoldItalicUnderline.find(x => x === 1) ?? null;

  //         let underLine = positionObj?.fontBoldItalicUnderline.find(x => x === 2) ?? null;

  //         // Check for bold and italic
  //         const fontWeight = bold != null ? 'bold' : 'normal';
  //         const fontStyle = italic != null ? 'italic' : 'normal';
  //         const fontFamily = positionObj.fontStyle ? positionObj.fontStyle : "Poppins, sans-serif";

  //         console.log(fontWeight, fontStyle, fontFamily);

  //         // Set the complete font property
  //         ctx.font = `${fontStyle} ${fontWeight} ${fontSize}px ${fontFamily}`;
  //         ctx.fillStyle = fontColor; // Set text color

  //         // Handle underline (this needs to be drawn manually as canvas does not support underline directly)
  //         if (underLine) {
  //           const textMetrics = ctx.measureText(userName);
  //           const underlineY = nameY + (textHeight / 2) + 8; // Position the underline slightly below the text
  //           ctx.beginPath();
  //           ctx.moveTo(nameX + (textWidth / 2) - (textMetrics.width / 2), underlineY);
  //           ctx.lineTo(nameX + (textWidth / 2) + (textMetrics.width / 2), underlineY);
  //           ctx.lineWidth = 1.5; // Underline thickness
  //           ctx.strokeStyle = fontColor; // Underline color matches text color
  //           ctx.stroke();
  //         }

  //         // Ensure text is centered both horizontally and vertically
  //         ctx.textAlign = 'center';
  //         ctx.textBaseline = 'middle';
  //         ctx.fillText(userName, nameX + textWidth / 2, nameY + textHeight / 2); // Center text within the box

  //       });

  //       // Export the canvas as a new image
  //       const updatedImageUrl = canvas.toDataURL('image/png');
  //       resolve(updatedImageUrl);
  //     };

  //     img.onerror = (error) => reject(error);
  //   });
  // },
};